.portlexDescription {
    background-color: #0D6CAC;
    color: white;
    width: auto;
    height: auto;
    padding: 10px 10px 10px 10px;
    font-size: 1rem;
    margin-top: 8px
}


.portlexDisclaimer {
    background-color: #e9ecec;
    color: black;
    width: auto;
    height: auto;
    padding: 10px 10px 10px 10px;
    font-size: 1rem;
    margin-top: 8px

}

.portlexDisclaimer  h1{
   margin-top: 0px !important;
}
.countryListHeader {
    width: auto;
    height: auto;
}

.countryList {
    height: auto;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
}

.cl-col {
    width: 30%;
}

.psmaagreement {
    padding: 3px 10px;
    display: inline-block;
}

.psmaagreement .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #0D6CAC;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

.psmaagreement:hover .tooltiptext {
    visibility: visible;
}

.portlexDescription h1 {
    font-size: 1rem;
    margin-bottom: 0%;

}

.portlexDisclaimer h1 {
    font-size: 1rem;
    margin-bottom: 0%;

}