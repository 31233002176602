body {
    color: #7f7f7f;
    background-color: #F7F8F9;
    font-family: 'Open Sans', sans-serif;
}

.container {
    width: 90%!important;
}

.cluster_summary .title .s10,
.gle_summary .title {
    font-size: 1.3rem;
    font-weight: 300;
    color: #5c5c5a;
    padding-left: 7px;
    margin-bottom: 12px;
}

.cluster_summary .title>.row {
    margin: unset;
}

.tab_content {
    box-shadow: 0 5px 4px -4px rgba(85, 85, 85, 0.5);
}

.tab_content .collection {
    overflow: auto;
    /* width: 340px; */
    border: none;
    background-color: #f7f8f9;
}

.tab_content>div {
    /* display: inline-flex; */
    display: inline-block;
}

.tab_content .left_content,
.tab_content .right_content {
    height: auto;
    position: relative;
    overflow: auto;
    margin-top: 30px;
    /* -webkit-box-shadow: -3px -2px 3px 0px rgba(184, 180, 184, 1);
    -moz-box-shadow: -3px -2px 3px 0px rgba(184, 180, 184, 1);
    box-shadow: -3px -2px 3px 0px rgba(184, 180, 184, 1); */
}

.left_content {
    width: auto;
}

.right_content .text {
    font-weight: 300;
}

.left_content li.collection-item {
    background-color: #f7f8f9!important;
}

.right_content:before {
    /* content: '';
    border-right: 2px solid #d2d7da;
    right: -30px; */
}

.collection.gle_ul_list li:hover {
    cursor: pointer;
}

.right_content {
    font-size: 0.9rem;
    width: 66%important;
    border-left: 2px solid #607d8b57;
    padding-left: 10px;
    background-color: #FFFFFF 0% 0% no-repeat padding-box;
    max-height: 700px;
    overflow: auto;
}

.right_content .title span {
    font-size: 1.3rem;
    font-weight: bold;
    width: 100%;
}

.tab.col {
    font-size: 0.8rem;
}

.tabs .tab a {
    text-align: left!important;
    text-transform: none !important;
    padding: 0 4px!important;
    font-size: 0.9rem!important;
    color: #575050!important;
    margin-top: 7px;
}

.t_header {
    height: 25%;
    background-color: white;
    text-align: center;
}

.tabs .tab {
    line-height: 30px;
}


/*Color of underline*/

#test1 .col.s9 {
    /* padding: 0px!important; */
}

.gle_tabs .collection .collection-item {
    font-size: 0.9rem;
    line-height: 1rem;
    padding: 1px 0px;
    margin: 0;
    /* border-bottom: 1px solid #e0e0e0; */
    padding-left: 5px!important;
    border: none;
    margin-bottom: 25px;
}

.this_header {
    padding: 10px;
    margin-top: 30px;
    color: white;
    background: var(--unnamed-color-529054) 0% 0% no-repeat padding-box;
    background: #529054 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.extended_header {
    padding: 0px;
}


/* .row.contents */

.this_header {
    margin-left: 0px;
}

.nav-wrapper.initial {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.nav-wrapper.initial {
    margin-top: 0px;
    color: #7f7f7f;
    background-color: inherit;
    /* height: 120px; */
}

.nav-wrapper.initial p,
.extended_header {
    /* top right bottom left */
    margin-top: 20px;
}

.extended_header p {
    margin-bottom: 8px;
}

.nav-wrapper.initial .this_header p:first-child {
    margin-top: 10px;
}

.maff {
    display: none;
}

.extended_header img {
    width: 25%;
    margin-left: 35%;
}

.top-nav {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
}

.international_commitments {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/34px var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-367cb8);
    text-align: left;
    font: normal normal normal 14px/34px Open Sans;
    letter-spacing: 0px;
    color: #367CB8;
    opacity: 1;
    font-family: 'Open Sans', sans-serif;
}

.international_commitments li,
ul.forestry_rel_inst li,
ul.relevant_legislation li {
    margin: 8px;
    font-size: 0.9rem;
}

.collapsible.relevant_legislation {
    border-top: none;
    margin-top: 0px;
    top: -7px;
    position: relative;
}

.collapsible.relevant_legislation .collapsible-header {
    background-color: unset;
}

.relevant_legislation_title,
.forestry_rel_inst_collapsible .collapsible-header {
    background: var(--unnamed-color-70ad47) 0% 0% no-repeat padding-box;
    background: #70AD47 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    color: white;
    padding: 14px;
}

.forestry_rel_inst_collapsible {
    padding: unset;
    background-color: inherit;
    color: grey;
}

.collapsible.relevant_legislation .collapsible-header {
    font-weight: 300;
}

.sidenav_react {
    width: 20%!important;
    /* margin-left: auto; */
    left: auto;
    right: auto;
}

.offset-m3 {
    margin-left: 20%!important;
    width: 80%!important;
}

.country_info {
    margin-right: 10px!important;
    margin-left: 10px!important;
    background-color: #F7F8F9;
}

.legal_framework.row {
    background: #529054 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    padding: 7px;
}

.legal_framework.row .btn {
    text-transform: none;
    -webkit-box-shadow: unset;
    box-shadow: unset;
    border-right: 1px solid white;
}

.legal_framework.row .btn:last-of-type {
    border-right: unset;
}

.legal_framework.row .col.s4 {
    background: #529054 0% 0% no-repeat padding-box;
}

.gle_tabs {
    border: 1px solid var(--unnamed-color-dadada);
}

.legal_framework.row,
.gle_tabs.row {
    margin-left: unset!important;
    margin-right: unset!important;
}


/* .btn.home::before {
    content: '';
    background: url(./img/home_off_Icon.png);
    height: 18px;
    width: 21px;
    position: absolute;
    background-size: 18px 16px;
    background-repeat: no-repeat;
    top: 20px;
    left: 10px;
} */

.btn {
    text-transform: none !important;
}

nav {
    height: 9vh;
}

@media all and (min-width: 1024px) and (max-width: 1400px) {
    /* //my mac at 100% */
    nav {
        height: 10vh;
    }
    html,
    body {
        padding-left: 4%;
        padding-right: 4%;
    }
}

.internal-link img {
    position: relative;
    height: 100%;
    width: 90%;
}

span.title_text {
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    margin: 0;
    /* margin-bottom: 30px; */
    /* bottom: 10px; */
}

.logo_container .title {
    display: inline-block;
    color: #756a6a;
    margin-left: 10px;
    margin-bottom: 8px;
    margin-top: 10px;
    bottom: 0%;
    /* margin: 0 auto; */
    border-left: 1px solid black;
    /* border-bottom: 27px; */
    padding-left: 30px;
    font-size: 2.8rem;
    height: 78%;
    position: relative;
}

.logo_container .divider {
    position: absolute;
    left: 50%;
    top: 10%;
    bottom: 10%;
    height: 73%;
    color: blueviolet;
}

.dropdown-content li>a,
.dropdown-content li>span {
    color: #26a69a;
    display: block;
    line-height: 35px;
    height: -5px;
    padding-left: 10px;
    padding-bottom: 0px;
    border-bottom: unset;
}

.dropdown-content.select-dropdown li {
    min-height: 35px!important;
    max-height: 35px!important;
}

.select_title {
    color: #343434ed;
    font-size: 1rem;
    padding: 10px;
    border: 1px solid #E8E8E8;
    font-weight: 350;
    background-color: #FFFFFF;
}

.multiple_search_div div.main_title {
    font-size: 1.2rem;
    color: #343434;
    /* border-top: 1px solid #4b4949; */
}

.compare_results .main_title {
    font-size: 0.8rem;
}

.compare_gle.row .caret {
    display: none;
}


/* 
.dropdown-content.select-dropdown {
    top: 35px!important;
} */

.top-nav .dropdown-content {
    top: 52px!important;
}

.top-nav .dropdown-content li {
    min-height: 34px!important;
    padding-top: 5px;
}

.language_dropdown {
    display: flex;
    text-transform: unset;
    background-color: white;
    color: grey;
    box-shadow: unset;
    -webkit-box-shadow: unset
}

#language_dropdown li {
    position: relative;
}

#language_dropdown li a {
    bottom: 40%;
    height: 100%;
    position: absolute;
}

.legal_system .s9 {
    font-size: 0.8rem;
}

.sel_language_span {
    display: inline-block!important;
}

.dropdown-content li:hover,
.dropdown-content li.active {
    background-color: #dae1d6;
}

.language_dropdown li:hover {
    cursor: pointer;
}

#language_dropdown li a:hover {
    background-color: unset;
    cursor: pointer;
}

.language_dropdown>div {
    position: relative;
    top: -20px;
}

.language_dropdown .material-icons {
    font-size: 1.5em;
    margin-left: 10px;
    display: inline;
    position: relative;
    top: 7px;
}

.row.card_top {
    margin: unset;
    text-align: center;
    padding-top: 30px;
}

.card_top img {
    margin-left: 50%;
    width: 20px;
}

.card_top .s9 div {
    float: left;
    margin-left: 6%;
}

.card {
    font-weight: 300;
}

.card_top .s12 {
    margin-bottom: 19px;
    padding: 0px;
    text-align: center;
    margin-left: 13%;
    margin-top: 15px;
    font-size: 1rem;
}

.card.forest_area .s9 {
    padding-left: 17px;
    text-align: left;
}

.row .col.s12.legal_title {
    width: 90%!important;
}

.card_top .s2 {
    margin-left: 19px;
}

.card_top .s9 {
    /* text-align: center; */
    font-size: 0.8rem;
}

.card_bottom img {
    /* margin-left: 53%; */
    display: none;
    width: 20px;
}

.card_bottom a {
    letter-spacing: var(--unnamed-character-spacing-0)!important;
    color: var(--unnamed-color-367cb8);
    letter-spacing: 0px;
    color: #367CB8;
    font-size: 0.6rem;
    margin-top: 5px;
}

.card_bottom .s12 {
    text-align: center;
}

.card_bottom {
    margin-top: 43px;
    font-size: 0.6rem;
}

.card-bottom .row {
    position: absolute;
    width: 100%;
    bottom: 0px;
    margin-bottom: 0px;
}

.card_bottom .col.s9 {
    margin-bottom: 15px;
    line-height: 10px;
}

.card_bottom .s9 a {
    text-align: center;
    font-size: 0.6rem;
}

.area_num {
    letter-spacing: var(--unnamed-character-spacing-0);
    font-size: 1rem;
    letter-spacing: 0px;
    color: #7D7D7D;
}

.area_p {
    display: block;
    font-size: 0.8rem;
}

.card {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 6px 9px #00000029;
    border-radius: 5px; */
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 0px;
}

.main_country_introduction_content>div {
    margin-left: 8%!important;
    margin-right: 8%!important;
}

.main_country_introduction_content .collapsible-header li {
    background-color: #f7f8f9;
}

.main_country_introduction_content .my_s {
    width: 28%!important;
    margin-right: 2.5%!important;
    /* 25%*3+(8% left/right *2) == 91*/
    font-size: 0.8rem;
    /* box-shadow: 0px 6px 9px #00000029!important; */
}

.main_country_introduction_content .my_s:first-of-type {
    margin-left: 6%;
}

.main_country_introduction_content .my_s:last-of-type {
    margin-right: unset;
}

a {
    color: var(--unnamed-color-367cb8)!important;
}

.legal_framework a {
    color: white!important;
}

.my_s .card {
    min-height: 183px;
}

.forestry_rel_inst.collection {
    padding-left: 0px;
}

.forestry_rel_inst li {
    margin: 10px;
}

.bottom_show_hide_gle_list {
    text-align: center;
    height: 30px;
    background-color: #93c571db;
    color: white;
    padding-top: 4px;
    margin-left: 5%;
    margin-right: 5%;
}

.top_show_hide_gle_list:hover,
.bottom_show_hide_gle_list:hover {
    cursor: pointer;
}

.top_show_hide_gle_list {
    opacity: 0;
    display: inline-block;
    font-size: 0.9rem;
    margin-top: 14px;
    color: teal;
}

.dropdown-trigger:focus,
.tabs .tab a:focus,
.tabs .tab a:focus.active {
    background-color: unset;
    outline: none;
}

.btn:hover,
.btn-large:hover,
.btn-small:hover,
.btn:focus,
.btn-large:focus {
    -webkit-transition: unset!important;
    transition: unset!important;
}

.language_dropdown.btn:hover {
    background-color: unset!important;
    -webkit-box-shadow: unset!important;
    box-shadow: unset!important;
}

.highlighted {
    color: #147aca !important;
}

.this_header {
    font-size: 0.8rem;
}

.nav-wrapper.country img {
    float: left;
    height: 34px;
    margin-top: 7px;
}

@media only screen and (min-width: 1200px) {
    html {
        font-size: 20px;
    }
}

@media all and (min-width: 1400px) {
    /* my macos at 90%, 1402px width */
    html {
        font-size: 14px;
    }
    /* html,
    body {
        padding-left: 5%;
        padding-right: 5%;
    } */
}

@media all and (min-width: 1024px) and (max-width: 1400px) {
    /* my macos at 100%*/
    html {
        font-size: 13px;
    }
}

.right_content .text {
    padding-left: 7px;
    padding-right: 5px;
}

.all_cont {
    margin-top: 1px;
}

.sidenav_react {
    -webkit-box-shadow: 6px 1px 5px -4px #9e9e9e;
    -moz-box-shadow: 6px 1px 5px -4px #9e9e9e;
    box-shadow: 6px 1px 5px -4px #9e9e9e;
}

.multiple_search_div {
    display: none;
    margin: 34px;
}

.multiple_search_div .dropdown-content.select-dropdown {
    opacity: 1!important;
    display: inline-block!important;
    max-height: 300px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E8E8E8;
    /* border-radius: 5px; */
    width: 100%;
}

.multiple_search_div .dropdown-content li>a,
.dropdown-content li>span {
    padding-top: 0px;
    padding-bottom: 5px;
}

.multiple_search_div .optgroup {
    background: #70AD47 0% 0% no-repeat padding-box!important;
    /* border-radius: 5px; */
    opacity: 1;
}

.multiple_search_div .select-dropdown li.optgroup>span {
    font-weight: 300;
    color: #ffffff !important;
    /* font-size: 1.1rem !important; */
}

.multiple_search_div .dropdown-content.select-dropdown {
    box-shadow: unset;
    -webkit-box-shadow: unset;
    position: relative!important;
}

.multiple_search_div .select-wrapper input.select-dropdown {
    display: none;
}


/* [type="checkbox"]+span:not(.lever) {
    font-size: 0.9rem!important;
    padding-left: 20px;
    color: #6D6D6D;
}

[type="checkbox"]+span:not(.lever):before,
[type="checkbox"]:not(.filled-in)+span:not(.lever):after {
    width: 14px;
    height: 14px;
    z-index: 0;
    border: 1px solid #5a5a5a;
    border-radius: 0px;
    margin-top: 4px;
}

[type="checkbox"]:checked+span:not(.lever):before {
    top: 0px;
    left: 0px;
    width: 5px;
    height: 13px;
} */

[type="checkbox"]+span:not(.lever) {
    position: relative;
    padding-left: 20px;
    margin-right: 10px;
}

.btn-small {
    height: 21.4px;
    line-height: 20.4px;
    font-size: 0.8rem;
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
    transition: 0.6s;
    background-color: #0e1cc4;
}

.btn:focus,
.btn-large:focus,
.btn-small:focus,
.btn-floating:focus {
    background-color: #2771CD;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 11px;
}

 ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #9e9e9e;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


/* marker popups */

.mapboxgl-popup-content {
    min-width: 185px;
}

.marker {
    /* background-image: url('mapbox-icon.png'); */
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.mapboxgl-ctrl-bottom-right {
    position: absolute!important;
    pointer-events: none;
    z-index: 2!important;
}

.map_and_list>div {
    display: inline-block;
}

.explore_country {
    font-size: 0.8rem;
    color: #367CB8;
}

.explore_country:hover {
    cursor: pointer;
    font-weight: bolder;
}

.download_gle_info:hover {
    cursor: pointer;
}

.download_gle_info {
    color: #00bcd4;
    text-decoration: underline;
}

.btn:hover,
.btn-large:hover,
.btn-small:hover,
.btn:hover,
.btn-large:hover,
.btn-small:hover,
.btn:focus,
.btn-large:focus,
.btn-small:focus,
.btn-floating:focus,
.btn:hover,
.btn-large:hover,
.btn-small:hover,
.btn-floating:hover {
    background-color: #ebebeb 0% 0% no-repeat padding-box!important;
    /* -webkit-box-shadow: unset!important; */
    /* 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%); */
    /* box-shadow: unset!important; */
    /* 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%); */
}

.legal_system .s9 {
    text-align: left!important;
}

.card_top .s9 span,
.card_top .s9 div {
    text-align: left!important;
    display: block!important;
    width: 100%;
    padding-right: 15px;
}


/* NEW FOR PORTLEX */