/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */


/*#allresults.loading {overflow: hidden;}    */


/* Anytime the body has the loading class, our
   modal element will be visible */


/*#allresults.loading .modal {display: block;}   */


/* #preview-pdf {display:none;} */

.keymatch-nav {
    display: none;
}

.filter-nav {
    display: none;
}

#preview-pdf {
    display: none;
    position: absolute;
}

span.filepreview {
    margin-right: 0px;
}

a.fdr_moreDetails {
    margin-right: 5px;
}

div.item-label {
    float: left;
    margin-right: 3px;
}

div.item-label img {
    margin-top: -3px;
    width: 20px !important;
    margin-right: 2px;
}

#rightcolumn {
    padding-top: 5px;
}

#rightcolumn h3.facet-title {
    margin-top: 25px;
    margin-bottom: 7px;
    clear: left;
    font-size: 1.0em;
    font-weight: 600;
}

#rightcolumn h3.facet-title:first-of-type {
    margin-top: 0;
}

#rightcolumn .facet-list-header {
    font-size: 1.0em;
    text-align: center;
    margin-bottom: 4px;
    color: #767777;
    font-weight: 600;
    text-transform: uppercase;
    /* border-top: 2px solid rgba(169, 169, 169, 0.67); */
    margin-top: 10px;
    padding-top: 3px;
}

.facet-list-header span {
    font-size: 0.9rem;
    color: #0a486d;
    padding: 5px;
    margin-top: 10px;
    text-align: center;
}

.facet-list-wrapper ul.collapsible {
    width: 100%;
}


/*
div.item-url,
div.item-value {margin:6px 0 10px 0;}
*/

div.pagination-nav {
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

div.page {
    display: inline;
    /*margin-right:8px;*/
}

#content div.page a.selected,
#content a.page-links:hover {
    background-color: #7f9ca0;
    color: #fff;
}

#content a.page-links {
    color: #000000;
    cursor: pointer;
    display: inline;
    margin-right: 8px;
    text-decoration: underline;
    background-color: #d7d6d6;
    border: 0 none;
    font-size: 0.875em;
    margin: 0 1px;
    padding: 7px 9px;
    text-decoration: none;
}

div.searchbox {
    margin-top: 40px;
    margin-bottom: 0px;
    min-height: 75px;
}

div.searchbox div.sbHolder {
    float: left;
    margin-right: 15px;
    border: 1px solid #cfcfcf;
}

div.searchbox form:after {
    content: '';
    display: block;
    clear: both;
}

input.ui-autocomplete-input {
    float: left;
    margin-right: 15px;
    height: 18px;
    border: 1px solid #cfcfcf;
    font-size: 13px;
    /*padding: 4px; width: 425px; */
    font-family: inherit;
}

.searchbox .queries .main-search {
    margin: 0 auto;
    /* width: 780px; */
}

.main-search input#query {}

.searchbox .queries input#query {
    float: left;
    margin-right: 15px;
    height: 30px;
    border: 1px solid #cfcfcf;
    font-size: 13px;
    padding: 4px;
    font-family: inherit;
    width: 80%;
    height: 20px;
    margin-left: 15%;
}

ul.ui-autocomplete {
    font-family: inherit !important;
}

ul.ui-autocomplete li.ui-menu-item a {
    background: none #ffffff !important;
    border: none !important;
    border-radius: 2px !important;
    color: #333333;
    margin: 0px !important;
}

ul.ui-autocomplete li.ui-menu-item a:hover {
    background: none #0e6da5 !important;
    border: none !important;
    border-radius: 2px !important;
    color: #ffffff;
    margin: 0px !important;
}

input#btnG {
    background: rgba(0, 0, 0, 0) linear-gradient(#0e6da5, #0d5399) repeat scroll 0 0;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0 2px 3px #d1cece;
    color: #fff !important;
    font-size: 1em !important;
    height: 29px !important;
    line-height: 29px !important;
    margin-right: 12px;
    padding: 0 10px !important;
    position: relative;
    font-family: inherit;
    text-transform: uppercase;
}

div.filter-list {
    margin: -11px 0px 15px;
}

div.filter-list .label {
    /* font-weight: bold; */
}

div.item-title {
    margin-bottom: 3px;
}

div.item-title p {
    margin-bottom: 5px;
    line-height: 1.5em;
}

div.item-title p a {
    font-weight: normal;
    font-size: 1.2em;
}

div.item {
    border-bottom: 1px solid #d1d1d1;
    margin-top: 10px;
    padding-bottom: 10px;
}

div.item-text {
    margin-bottom: 4px;
}

div.item-text p {
    margin-bottom: 0;
}

div.item-text strong {}

.embed {
    float: right;
    margin-left: 10px;
    margin-bottom: 10px;
}

div.item-text .embed {
    float: none;
    margin-left: 3px;
}

div.item-url {
    color: rgb(92, 165, 106);
    margin-top: -5px;
}

div.item-url p {
    margin-top: 6px;
}

div.item-alt-card {
    margin-top: 10px;
}

div.item-title img {
    float: left;
    margin-right: 15px;
    margin-bottom: 12px;
}

div.item-title div.fdr_results_av_stars img {
    margin-bottom: 4px;
    margin-right: 0px;
}

div.item-title div.fdr_results_av_stars span {
    margin: 0 5px;
}

div.facet-item {
    margin-bottom: 4px;
    margin-left: 10px;
}

.loadMore {
    cursor: pointer;
    color: #003b43;
    float: left;
    height: 35px;
    margin-right: 15px;
    margin-left: 10px;
}

.loadMore:hover,
.showLess:hover {
    text-decoration: underline;
}

.showLess {
    cursor: pointer;
    color: #003b43;
    margin-left: 10px;
}


/* .loadMore:after,
.showLess:after{display:block; content:''; clear:left;} */

.fa-plus-square-o,
.fa-minus-square-o {
    margin-left: 8px;
}

#allresults {
    position: relative;
}

#loader {
    display: none;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-height: 250px;
    min-width: 700px;
    /*background: rgba( 255, 255, 255, .8 ) 
url('http://www.fao.org/fileadmin/templates/gsa/img/ajax-loader.gif') 50% 50% no-repeat;*/
}

#pdf-tooltip {
    display: none;
    position: absolute;
}

#allresults .fa {
    font-size: 23px;
}

.fa-file-pdf-o {
    color: #B30000;
}

.fa-search-plus {
    color: #00566E;
}

.filter-links {
    font-weight: bold;
}

.fa-times {
    margin-left: 4px;
    margin-right: 10px;
    border: 1px solid #e0e0e0;
    color: #AAA;
    padding: 0px 2px;
    font-size: 12px;
}

.filter-stats {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 25px;
    /*font-size: 16px;*/
}

.keymatch-nav {
    padding: 5px 10px;
    border: 1px solid #f0f0f0;
    background: #f8f8f8;
}

.keymatch-nav h3 {
    margin: 0px !important;
    border: none !important;
    font-size: 14px !important;
}

ul.keymatch-list {
    margin: 4px !important;
}

ul.keymatch-list li {
    margin-bottom: 1px !important;
}

.button-search {
    background: transparent linear-gradient(rgb(14, 109, 165), rgb(13, 83, 153)) repeat scroll 0px 0px;
    width: 28px;
    border-radius: 1px;
    color: rgb(255, 255, 255);
    text-align: center;
    float: left;
    height: 28px;
    font-size: 17px;
    line-height: 26px;
    margin-left: -17px;
    cursor: pointer;
}

.button-search:hover {
    opacity: 0.8;
}

.previewer {
    display: none;
    position: absolute;
}

.country .item-value {
    color: #474747;
}

.bottom-items {
    clear: left;
    margin-top: 5px;
}

.bottom-items .item-text {
    margin-bottom: 3px;
    font-size: 0.9em;
    /*padding-left:10px;*/
    float: left;
    width: 50%;
}

.bottom-items .item-text .item-label {
    min-width: 85px;
    background: #f0f0f0;
    padding: 4px;
    margin-right: 8px;
}

.bottom-items .item-text .item-value {
    padding: 4px;
    /*border-bottom: 1px solid #f0f0f0;*/
}

.detail-page div.item {
    border-bottom: none;
}

.detail-page .bottom-items {
    background: none;
    padding: 5px 0px 0px;
}

.detail-page .bottom-items .item-text {
    font-size: 1.0em;
    padding-left: 0px;
}

.detail-page .bottom-items .item-text .item-label {
    min-width: 130px;
    background: #f0f0f0;
    padding: 4px;
    margin-right: 5px;
}

.detail-page .bottom-items .item-text .item-value {
    padding: 4px;
    border-bottom: 1px solid #f0f0f0;
}

.detail-page .bottom-items .meetingDescription {
    clear: left;
    width: 100%;
}

.detail-page .bottom-items .meetingDescription .item-value {
    margin-left: 140px;
}

.detail-page .embed {
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 25px;
    margin-right: 5px;
}


/* .detail-page .bottom-items .item-text .item-label b,
.detail-page .bottom-items .item-text .item-value {font-weight: normal;} */

#adv_search {
    clear: left;
    /* width: 780px; */
    width: auto;
    margin: 0 auto;
    /* padding: 15px; border: 3px solid #f0f0f0; border-radius: 5px; background-color:#f9f9f9;*/
    padding-bottom: 20px;
    border-bottom: 1px dashed #a1b7d7;
    margin-bottom: 15px;
    padding-top: 5px;
}


/* #adv_search,#hideAdvSearch {display:none;} */

#showAdvSearch,
#hideAdvSearch {
    display: none;
    float: right;
    border: 1px solid #c0c0c0;
    border-radius: 2px;
    background: #feffff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #feffff 0%, #f0f0f0 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #feffff 0%, #f0f0f0 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #feffff 0%, #f0f0f0 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#feffff', endColorstr='#f0f0f0', GradientType=0);
    /* IE6-9 */
    padding: 3px 10px;
    text-align: right;
    position: relative;
    top: 5px;
    text-transform: uppercase;
    font-size: 0.9em;
}

#showAdvSearch a,
#hideAdvSearch a {
    color: #0f68b4;
}

#showAdvSearch {
    right: 10px;
}

#hideAdvSearch {
    /* right: 30px; */
    top: -25px;
}

#adv_search .fdr_label {
    float: left;
    /* width: 178px; */
    margin-right: 10px;
    /* position: absolute; */
}

#adv_search .fdr_label,
#adv_search .fdr_text {
    /* min-height: 30px;
    margin-bottom: 2px;
    position: relative; */
    margin-top: 9px;
    text-align: right;
}

#adv_search .fdr_label span {
    /* position: absolute;
    top: 25%; */
}

#adv_search .fdr_text input {
    /* width: 575px; */
    font-family: inherit;
    height: 24px;
    border: 1px solid #cfcfcf;
}

#adv_search .fdr_btns {}

#adv_search .fdr_btns input {
    background: linear-gradient(to bottom, #0e6da5 0%, #0d5399 100%);
    margin-right: 15px;
    border: none;
    color: #ffffff;
    font-family: inherit;
    padding: 6px 20px;
    /* border-radius: 5px; */
    font-size: 0.9em;
    /* font-weight: bold; */
    text-transform: uppercase;
    width: auto;
}

#adv_search .fdr_btns input:hover {
    opacity: 0.9;
}


/* #adv_search .fdr_btns input#resetSearch {background: linear-gradient(to bottom, #bbb 0%,#aaa 100%)} */

#adv_search .fdr_query {
    float: none;
    /* padding-left: 150px; */
    margin-bottom: 15px;
}

#adv_search .fdr_query:after {
    content: '';
    display: block;
    clear: both;
}

#adv_search .fdr_query input {
    /*padding-left: 150px;*/
    /* width: 740px !important; */
}


/*

.chosen-container-multi .chosen-choices li.search-field {width: 10px;}

*/

.chosen-container-multi .chosen-choices {
    padding-left: 0px !important;
    border: 1px solid #ddd !important;
    border-top: 1px solid #aaa !important;
    background-image: none !important;
}

#fdr_deflang_chosen,
#fdr_country_chosen {
    width: 577px !important;
    margin-top: -1px;
}

.chosen-container-multi .chosen-results {
    margin-left: -10px;
}

.chosen-container-multi .chosen-choices li {
    left: 0.5em;
}

.chosen-container-multi .chosen-choices li.search-choice {
    border: none;
    background: none !important;
    background-image: transparent !important;
}


/*#adv_search .fdr_label, .fdr_text {clear:left;}*/


/* sort by date / sort by relevance */

.multiple {
    margin-top: 10px;
}

.multiple:after {
    clear: both;
    display: block;
    content: '';
}

.multiple .item-label {
    font-size: 0.9em;
    width: 164px;
    margin-bottom: 5px;
}

.item-title {
    clear: both;
}

.country .item-value {
    margin-bottom: 6px;
}

.country .item-value .fa {
    font-size: 14px !important;
    color: rgb(131, 131, 131);
    position: relative;
    top: 1px;
    margin-left: 0px;
    margin-right: 2px;
}


/* detail GSA */

.dynafef_pi4_main .right_content {
    width: 200px;
    float: left;
}

.dynafef_pi4_main .thumb-image IMG {
    box-shadow: 2px 2px 5px #888888;
    margin-bottom: 10px;
}

.dynafef_pi4_main .content,
.dynafef_pi4_main .valueleft {
    margin-left: 220px;
}

.dynafef_pi4_main .content {
    margin-top: 10px;
}

.dynafef_pi4_main .fdr_label {
    font-weight: bold;
}

.dynafef_pi4_main .value {
    margin-top: 10px;
    margin-left: 0px;
}

.dynafef_pi4_main .value .fa-file-pdf-o {
    margin-left: 5px;
}


/* Checkbox */


/*#adv_search .fdr_check input {width: 100%; font-family: inherit; border:1px solid #cfcfcf;float: left}*/

#adv_search .fdr_check input {
    width: 10px;
    font-family: inherit;
    border: 1px solid #cfcfcf;
    float: left
}

#adv_search div .fdr_check .multi {
    width: 33%;
    font-family: inherit;
    border: 1px solid #cfcfcf;
    float: left
}

#adv_search .fdr_label .internalcheck {
    width: 50px !important;
    font-family: inherit;
    border: 1px solid #cfcfcf;
    float: left
}

div .fdr_label .internalcheck {
    width: 50px !important;
    font-family: inherit;
    border: 1px solid #cfcfcf;
    float: left
}

div .faocheck {
    width: 55px;
    float: left;
}

textarea#queryState {
    width: 0px;
    height: 0px;
    border: none;
    left: -9999px;
    position: absolute;
}