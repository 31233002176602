                                                                                                                        :root {
                                                                                                                           /* Colors: */
                                                                                                                           --unnamed-color-116aab: #116AAB;
                                                                                                                           --unnamed-color-367cb8: #367CB8;
                                                                                                                           --unnamed-color-70ad47: #70AD47;
                                                                                                                           --unnamed-color-529054: #529054;
                                                                                                                           --unnamed-color-ffffff: #FFFFFF;
                                                                                                                           --unnamed-color-586371: #586371;
                                                                                                                           --unnamed-color-707070: #707070;
                                                                                                                           --unnamed-color-9d9d9d: #9D9D9D;
                                                                                                                           --unnamed-color-dadada: #DADADA;
                                                                                                                           --unnamed-color-343434: #343434;
                                                                                                                           --unnamed-color-f47929: #F47929;
                                                                                                                           --unnamed-color-5f7b7f: #5F7B7F;
                                                                                                                           --unnamed-color-003b43: #003B43;
                                                                                                                           /* Font/text values */
                                                                                                                           --unnamed-font-family-open-sans: Open Sans;
                                                                                                                           --unnamed-font-style-normal: normal;
                                                                                                                           --unnamed-font-weight-normal: normal;
                                                                                                                           --unnamed-font-size-21: 21px;
                                                                                                                           --unnamed-font-size-16-000089645385742: 16.000089645385742px;
                                                                                                                           --unnamed-character-spacing-0: 0px;
                                                                                                                           --unnamed-line-spacing-21: 21px;
                                                                                                                           --unnamed-line-spacing-32-99990463256836: 32.99990463256836px;
                                                                                                                       }
                                                                                                                       /* Character Styles */
                                                                                                                       
                                                                                                                       .unnamed-character-style-1 {
                                                                                                                           font-family: var(--unnamed-font-family-open-sans);
                                                                                                                           font-style: var(--unnamed-font-style-normal);
                                                                                                                           font-weight: var(--unnamed-font-weight-normal);
                                                                                                                           font-size: var(--unnamed-font-size-21);
                                                                                                                           line-height: var(--unnamed-line-spacing-21);
                                                                                                                           letter-spacing: var(--unnamed-character-spacing-0);
                                                                                                                           color: var(--unnamed-color-1b1d1f);
                                                                                                                       }
                                                                                                                       
                                                                                                                       .unnamed-character-style-2 {
                                                                                                                           font-family: var(--unnamed-font-family-open-sans);
                                                                                                                           font-style: var(--unnamed-font-style-normal);
                                                                                                                           font-weight: var(--unnamed-font-weight-normal);
                                                                                                                           font-size: var(--unnamed-font-size-16-000089645385742);
                                                                                                                           line-height: var(--unnamed-line-spacing-32-99990463256836);
                                                                                                                           letter-spacing: var(--unnamed-character-spacing-0);
                                                                                                                           color: var(--unnamed-color-003b43);
                                                                                                                       }
                                                                                                                       /* fallback */
                                                                                                                       
                                                                                                                       @font-face {
                                                                                                                           font-family: 'Material Icons';
                                                                                                                           font-style: normal;
                                                                                                                           font-weight: 400;
                                                                                                                           src: url(https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
                                                                                                                       }
                                                                                                                       
                                                                                                                       .material-icons {
                                                                                                                           font-family: 'Material Icons';
                                                                                                                           font-weight: normal;
                                                                                                                           font-style: normal;
                                                                                                                           font-size: 24px;
                                                                                                                           line-height: 1;
                                                                                                                           letter-spacing: normal;
                                                                                                                           text-transform: none;
                                                                                                                           display: inline-block;
                                                                                                                           white-space: nowrap;
                                                                                                                           word-wrap: normal;
                                                                                                                           direction: ltr;
                                                                                                                           -webkit-font-feature-settings: 'liga';
                                                                                                                           -webkit-font-smoothing: antialiased;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sidenav_sel_label {
                                                                                                                           margin-bottom: 5px;
                                                                                                                           font-size: 0.9rem;
                                                                                                                           padding-left: 3px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #react-select-2-input {
                                                                                                                           height: auto!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .my_faoSelect__value-container--is-multi.my_faoSelect__value-container--has-value {
                                                                                                                           height: auto;
                                                                                                                           color: #367CB8;
                                                                                                                           font-size: 0.6rem!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .my_faoSelect__control {
                                                                                                                           height: auto!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #vmFreeText {
                                                                                                                           background-color: white;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-nav {
                                                                                                                           display: none;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-nav-info {
                                                                                                                           border: 1px solid #dce1e4;
                                                                                                                           font-size: 0.8rem;
                                                                                                                           padding: 8px;
                                                                                                                           border-bottom: 0px;
                                                                                                                           background: #dde2e5;
                                                                                                                           color: black;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #allresults .comparing_div:first-child {
                                                                                                                           margin-top: 0px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #allresults table tbody tr>td:first-of-type span {
                                                                                                                           /* font-weight: 600; */
                                                                                                                       }
                                                                                                                       
                                                                                                                       .react-expand-collapse_link {}
                                                                                                                       
                                                                                                                       .react-expand-collapse__button {}
                                                                                                                       
                                                                                                                       html,
                                                                                                                       body {
                                                                                                                           font-family: sans-serif;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tabs {
                                                                                                                           border: #dadada;
                                                                                                                           background-color: #F7F8F9;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tabs .tab a {
                                                                                                                           text-align: left!important;
                                                                                                                           text-transform: none !important;
                                                                                                                           padding: 0 4px!important;
                                                                                                                           font-size: 1rem!important;
                                                                                                                           color: #575050!important;
                                                                                                                           margin-top: 0px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tabs .active {
                                                                                                                           /* same color as body */
                                                                                                                           background-color: #F7F8F9;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tab_info_container {
                                                                                                                           background-color: #F7F8F9;
                                                                                                                           border-top: unset;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .hidden {
                                                                                                                           display: none;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .inactive {
                                                                                                                           color: white;
                                                                                                                           background: #74a0d1;
                                                                                                                           font-weight: bold;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #main-searchdiv {
                                                                                                                           margin-top: 20px;
                                                                                                                           margin-left: 23px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #allresults table {
                                                                                                                           background-color: white;
                                                                                                                           color: #131111;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .mapboxgl-popup-close-button:focus {
                                                                                                                           background-color: unset;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #adv_search .fdr_label span {
                                                                                                                           color: #4d4d4d;
                                                                                                                           float: left;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .filter-list a {
                                                                                                                           font-size: 0.7rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-title {
                                                                                                                           color: black;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #content div.main-internal {
                                                                                                                           /* border-left: 2px solid #607d8b57; */
                                                                                                                           /* padding: 15px 20px 0 20px; */
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-list-wrapper ul.collapsible {
                                                                                                                           padding-left: 0px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-list-wrapper ul.collapsible .collapsible-body {
                                                                                                                           border-bottom: 1px solid #ddd;
                                                                                                                           box-sizing: border-box;
                                                                                                                           padding: 0.2rem;
                                                                                                                           font-size: 1.2rem!important;
                                                                                                                           background-color: white;
                                                                                                                           max-height: 250px;
                                                                                                                           display: block;
                                                                                                                           overflow-y: auto;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-list-wrapper ul.collapsible .collapsible-header {
                                                                                                                           padding: 7px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-list-wrapper ul.collapsible .collapsible-body .facet-item {
                                                                                                                           margin-bottom: 4px;
                                                                                                                           margin: 7px;
                                                                                                                       }
                                                                                                                       /* .facet-list-wrapper ul.collapsible .collapsible-body .facet-item a { */
                                                                                                                       
                                                                                                                       .facet-list-wrapper ul.collapsible .collapsible-body .facet-item {
                                                                                                                           color: #0D6CAC;
                                                                                                                           padding-right: 5px;
                                                                                                                           text-decoration: none;
                                                                                                                           text-align: left;
                                                                                                                           font-size: 0.8rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-item .facet-selected {
                                                                                                                           font-weight: bolder;
                                                                                                                           color: #e0912b;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-list-wrapper ul.collapsible .collapsible-body .facet-links:hover {
                                                                                                                           cursor: pointer;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-list-wrapper ul.collapsible .collapsible-body .facet-item .row {
                                                                                                                           margin-bottom: 0px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .my_blue.badge {
                                                                                                                           line-height: 16px;
                                                                                                                           height: 15px;
                                                                                                                           color: white;
                                                                                                                           background-color: #0e6da5;
                                                                                                                           font-size: 0.8rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-item .my_blue.badge {
                                                                                                                           font-size: 0.6rem;
                                                                                                                           min-width: 1rem;
                                                                                                                           position: relative;
                                                                                                                           /* top: -5%; */
                                                                                                                           transform: translateY(-5%);
                                                                                                                           margin-right: 3px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tab a {
                                                                                                                           margin-top: 0px;
                                                                                                                           position: relative;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tab a span {
                                                                                                                           /* font-size: 1.1rem!important; */
                                                                                                                           color: #7d7373;
                                                                                                                           font-weight: bolder;
                                                                                                                           position: absolute;
                                                                                                                           bottom: 7px;
                                                                                                                           left: 13px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tab.col.s3 {
                                                                                                                           border: 0.5px solid #ddd;
                                                                                                                           border-bottom: unset;
                                                                                                                           border-top-right-radius: 10px;
                                                                                                                           border-top-left-radius: 10px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tab.col.s3:first-of-type {
                                                                                                                           margin-right: 3px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tabs .indicator {
                                                                                                                           /* background-color: #000; */
                                                                                                                           top: 40px;
                                                                                                                           height: 8px;
                                                                                                                           /* background-color: #000; */
                                                                                                                           /* top: 32px; */
                                                                                                                           /* background-color: #4CAF50; */
                                                                                                                           width: 10%;
                                                                                                                           margin-left: 13px;
                                                                                                                           background-color: #2b7db5;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .main-internal {
                                                                                                                           position: relative;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .results_right_filter_btns {
                                                                                                                           /* position: absolute;
    top: 0px;
    left: 0px; */
                                                                                                                           padding-left: 3px;
                                                                                                                           padding-right: 3px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .results_right_filter_btns .btn {
                                                                                                                           /* padding: 10px; */
                                                                                                                           color: white;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .results_right_filter_btns .btn i {
                                                                                                                           margin-left: 5px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sortby-nav>span {
                                                                                                                           font-size: 0.9rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .results_right .btn {
                                                                                                                           text-overflow: ellipsis!important;
                                                                                                                           overflow: hidden;
                                                                                                                           white-space: nowrap;
                                                                                                                           height: 24px;
                                                                                                                           line-height: 24px;
                                                                                                                           /* padding: 0 0.5rem; */
                                                                                                                           margin-bottom: 7px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .results_right_filter_btns .btn span {
                                                                                                                           position: relative;
                                                                                                                           bottom: 3px;
                                                                                                                           color: white;
                                                                                                                           font-size: 0.7rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sortby-nav>div {}
                                                                                                                       
                                                                                                                       .sortby-nav {
                                                                                                                           /* position: absolute; */
                                                                                                                           /* right: 260px; */
                                                                                                                           font-size: 0.9em;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .refine_filter_container_row {
                                                                                                                           margin-bottom: 0px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .refine_filter_container {
                                                                                                                           /* margin-left: 15px; */
                                                                                                                           position: relative;
                                                                                                                           top: -10px;
                                                                                                                           left: 10px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .valign-wrapper.refine_filter_container_parent {
                                                                                                                           margin: 0px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .refine_filter span {
                                                                                                                           bottom: 6px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .page-content a:hover {
                                                                                                                           cursor: pointer;
                                                                                                                           color: #039be5;
                                                                                                                           font-style: italic;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .mapboxgl-popup-content {
                                                                                                                           min-width: 200px;
                                                                                                                           padding: 5px!important;
                                                                                                                           border-radius: unset;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .country_popup .badge {
                                                                                                                           position: relative;
                                                                                                                           bottom: 5px;
                                                                                                                           height: auto;
                                                                                                                           min-width: 1.6rem!important;
                                                                                                                           /* margin-right: 25%; */
                                                                                                                       }
                                                                                                                       
                                                                                                                       .country_popup .row.country_popup_header {
                                                                                                                           height: auto;
                                                                                                                           color: #203a20;
                                                                                                                           margin-bottom: 2px!important;
                                                                                                                           margin-top: 8px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .country_popup .col.s3,
                                                                                                                       .country_popup .col.s4 {
                                                                                                                           position: absolute !important;
                                                                                                                           /* top: 43%; */
                                                                                                                           left: 70%;
                                                                                                                           margin: 0;
                                                                                                                           position: absolute;
                                                                                                                           top: 50%;
                                                                                                                           -ms-transform: translateY(-50%);
                                                                                                                           transform: translateY(-50%);
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sortby_title_container {
                                                                                                                           position: relative;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sortby_title_container span:hover {
                                                                                                                           cursor: pointer;
                                                                                                                           font-weight: bold;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sortby_list .sort-active {
                                                                                                                           font-weight: bold;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .country_popup .col.s9,
                                                                                                                       .country_popup .col.s8 {
                                                                                                                           position: absolute !important;
                                                                                                                           top: 50%;
                                                                                                                           font-weight: 330;
                                                                                                                           font-size: 0.9rem;
                                                                                                                           line-height: 0.9rem;
                                                                                                                           color: #7f7f7f;
                                                                                                                           margin-left: 8px;
                                                                                                                           -ms-transform: translateY(-50%);
                                                                                                                           transform: translateY(-50%);
                                                                                                                       }
                                                                                                                       
                                                                                                                       .country_popup .row {
                                                                                                                           margin-bottom: 6px !important;
                                                                                                                           position: relative !important;
                                                                                                                           height: 35px;
                                                                                                                           margin: unset;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .countryList {
                                                                                                                           overflow-y: auto;
                                                                                                                           overflow-x: hidden;
                                                                                                                           width: 100%;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .countryList .row {
                                                                                                                           width: 100%;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .countryList .row ul {
                                                                                                                           margin-left: 5px;
                                                                                                                           padding-right: 10px;
                                                                                                                           margin-top: 0px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .countryListHeader_title {
                                                                                                                           font-size: 1.4rem;
                                                                                                                           text-align: center;
                                                                                                                           margin-top: 10px;
                                                                                                                           color: #6b6464;
                                                                                                                           text-decoration: underline;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #legend {
                                                                                                                           padding: 10px;
                                                                                                                           box-shadow: 0 1px 2px #bfb2b2;
                                                                                                                           background-color: #e6efe6;
                                                                                                                           line-height: 18px;
                                                                                                                           height: auto;
                                                                                                                           margin-bottom: 40px;
                                                                                                                           width: auto;
                                                                                                                           right: 100px;
                                                                                                                           margin-right: 23px;
                                                                                                                           text-align: left !important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .map_legend_control_container {
                                                                                                                           position: relative;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .map_legend_control_container div {
                                                                                                                           /* width: 200px;
height: 100px; */
                                                                                                                           color: black;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .c-right-item {
                                                                                                                           text-align: left;
                                                                                                                           font-size: 2rem;
                                                                                                                           text-transform: uppercase;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .busy-load-container {
                                                                                                                           position: fixed;
                                                                                                                           top: 0px;
                                                                                                                           left: 0px;
                                                                                                                           background: #0D6CAC;
                                                                                                                           color: rgb(255, 255, 255);
                                                                                                                           display: flex;
                                                                                                                           align-items: center;
                                                                                                                           justify-content: center;
                                                                                                                           width: 100%;
                                                                                                                           height: 100%;
                                                                                                                           z-index: 9999;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .busy-load-container-item {
                                                                                                                           background: none;
                                                                                                                           display: flex;
                                                                                                                           justify-content: center;
                                                                                                                           align-items: center;
                                                                                                                           flex-direction: row-reverse;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .busy-load-container .rect {
                                                                                                                           background-color: #fff;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .spinner-accordion,
                                                                                                                       .busy-load-spinner-css,
                                                                                                                       .busy-load-spinner {
                                                                                                                           max-height: 50px;
                                                                                                                           max-width: 50px;
                                                                                                                           min-height: 20px;
                                                                                                                           min-width: 20px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .header {
                                                                                                                           color: white;
                                                                                                                           margin-bottom: 0px;
                                                                                                                       }
                                                                                                                       /* .header .s8 {
                                            color: white;
                                            margin-top: 20px;
                                        }
                                        
                                        .header .s9 {
                                            float: 'left';
                                            padding-left: '10px';
                                            padding-top: '5px';
                                        } */
                                                                                                                       
                                                                                                                       .sidenav_react {
                                                                                                                           padding: unset;
                                                                                                                       }
                                                                                                                       
                                                                                                                       ul.suggestions {
                                                                                                                           max-height: 350px;
                                                                                                                           margin-bottom: 0px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .rfmo_container {
                                                                                                                           padding: 10px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .btn,
                                                                                                                       .btn-large,
                                                                                                                       .btn-small {
                                                                                                                           background-color: #0f6cac;
                                                                                                                           font-size: 0.8rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .search-app-container {
                                                                                                                           margin-left: 5px;
                                                                                                                           padding-right: 10px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sidenav_react .btn {
                                                                                                                           width: 100%;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .pagination_container {}
                                                                                                                       
                                                                                                                       .main-search {
                                                                                                                           /* width: 80%; */
                                                                                                                       }
                                                                                                                       
                                                                                                                       .main-search,
                                                                                                                       #adv_search {
                                                                                                                           margin-left: 10px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       div.searchbox {
                                                                                                                           margin-top: 0px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #verticalMenu button.accordion {
                                                                                                                           font-size: 1rem;
                                                                                                                           border: 1px solid lightgrey;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #adv_search .fdr_label span {
                                                                                                                           font-size: 1rem!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .results_right_filter_btns {
                                                                                                                           margin-bottom: unset;
                                                                                                                           margin-left: 15px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tab.col.s3 {
                                                                                                                           border: 0.5px solid #ddd;
                                                                                                                           border-bottom: unset;
                                                                                                                           border-radius: unset;
                                                                                                                           /* border-top-right-radius: 10px; */
                                                                                                                           /* border-top-left-radius: 10px;*/
                                                                                                                       }
                                                                                                                       
                                                                                                                       #allresults table thead th.country_name {
                                                                                                                           width: 85%;
                                                                                                                           color: #0f6cac;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #allresults table span {
                                                                                                                           margin-right: 10px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #allresults table thead span.big {
                                                                                                                           font-size: 1.3rem;
                                                                                                                           color: #0f6cac;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #allresults table td span.big {
                                                                                                                           /* font-size: 1.3rem; */
                                                                                                                           color: #0f6cac;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #allresults table span.small {
                                                                                                                           /* font-size: 1rem; */
                                                                                                                       }
                                                                                                                       
                                                                                                                       i.print_pdf {
                                                                                                                           float: right;
                                                                                                                           margin-right: 20px;
                                                                                                                           color: #b41b1b;
                                                                                                                       }
                                                                                                                       
                                                                                                                       i.print_pdf:hover {
                                                                                                                           cursor: pointer;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #content .results_right {
                                                                                                                           display: none;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .results_left {
                                                                                                                           background-color: white;
                                                                                                                           margin-right: 0px;
                                                                                                                           border: 1px solid #efefef;
                                                                                                                           border-right: unset;
                                                                                                                           display: none;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .row.sortby_list {
                                                                                                                           margin-bottom: 0px!important;
                                                                                                                           width: 100%;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sortby-nav {
                                                                                                                           margin-top: 1px;
                                                                                                                           margin-right: 5px;
                                                                                                                           font-size: 0.8rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sortby-nav>div {
                                                                                                                           display: inline-block;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sortby-nav-title {
                                                                                                                           width: 100%;
                                                                                                                           text-align: center;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sortby-nav div.col {
                                                                                                                           text-decoration: underline;
                                                                                                                           text-align: center;
                                                                                                                           color: #0f6cac;
                                                                                                                           padding: 3px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list {
                                                                                                                           max-height: 1300px;
                                                                                                                           overflow-y: auto;
                                                                                                                           margin-left: 0px;
                                                                                                                           margin-top: 0px;
                                                                                                                           background: #0f6cac;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list table td,
                                                                                                                       .result-list table th {
                                                                                                                           /* padding: 5px 2px; */
                                                                                                                           padding: 6px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list table td {
                                                                                                                           padding-left: 0px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list table td.td_title {
                                                                                                                           padding-left: 5px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list table td a {
                                                                                                                           padding-left: 0px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list table {
                                                                                                                           background-color: white;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list span.sel {
                                                                                                                           display: block;
                                                                                                                           margin-top: 5px;
                                                                                                                           margin-bottom: 5px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .parent-item {
                                                                                                                           color: #211d1d!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list .parent-item-container img {
                                                                                                                           max-width: 30px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list .parent-item-container {
                                                                                                                           /* border: unset!important; */
                                                                                                                           margin: 2px;
                                                                                                                           margin-left: 7px;
                                                                                                                           font-size: 1rem;
                                                                                                                           margin-top: 2px;
                                                                                                                           background-color: white;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list .parent-item-container .badge {
                                                                                                                           min-width: 1.4rem;
                                                                                                                           min-height: 1.3rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .result-list table tbody .td_title {
                                                                                                                           font-weight: 400;
                                                                                                                           min-width: 15%;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .pagination_title {
                                                                                                                           margin-bottom: 0px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-nav li:hover {
                                                                                                                           cursor: pointer;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-nav ul {
                                                                                                                           margin-left: 0px;
                                                                                                                           margin-right: 0px;
                                                                                                                           margin-bottom: 0px;
                                                                                                                           margin-top: 0px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-nav .collapsible-header {
                                                                                                                           font-size: 1rem;
                                                                                                                           background-color: #0e84d5d9;
                                                                                                                           color: white;
                                                                                                                           padding: 0.5rem;
                                                                                                                           padding-left: 0px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-nav .collapsible-header .col.s10 {
                                                                                                                           padding-left: 5px;
                                                                                                                           font-weight: bolder;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sortby_list .col {
                                                                                                                           display: inline;
                                                                                                                           color: blue;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-nav ul li {
                                                                                                                           width: 100%;
                                                                                                                           margin-bottom: 0px;
                                                                                                                           margin-top: 0px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .query_results_count_wrapper {
                                                                                                                           display: none;
                                                                                                                           text-align: center;
                                                                                                                           font-size: 1.4rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .query_results_count_filtered {
                                                                                                                           display: none;
                                                                                                                           font-size: 1rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .query_results_count span {
                                                                                                                           font-size: 1.9rem;
                                                                                                                           color: #0f6cac;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-nav .collapsible-body {
                                                                                                                           background-color: white;
                                                                                                                           padding: 0px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .collapsible-body div {
                                                                                                                           font-size: 0.9rem;
                                                                                                                           line-height: 1.3rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .total_records {
                                                                                                                           font-size: 2rem;
                                                                                                                           display: 'block';
                                                                                                                           color: #586371;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .no-records.test {
                                                                                                                           font-size: 2rem;
                                                                                                                           display: 'block';
                                                                                                                           color: #586371;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .tab {
                                                                                                                           background-color: white!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #content div.main-internal,
                                                                                                                       .searchbox.queries {
                                                                                                                           border-top: 1px solid #efefef;
                                                                                                                           border-left: 2px solid #efefef;
                                                                                                                           background-color: white;
                                                                                                                           padding-left: 0px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .searchbox .tabs {
                                                                                                                           width: 70%;
                                                                                                                           margin-right: 27%;
                                                                                                                           background: white;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #results_tab #content {
                                                                                                                           margin-left: unset!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #adv_search .fdr_query {
                                                                                                                           float: none;
                                                                                                                           margin-bottom: 15px;
                                                                                                                           display: flex;
                                                                                                                           align-items: center;
                                                                                                                           justify-content: center;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .provision_type {
                                                                                                                           text-align: 'left';
                                                                                                                       }
                                                                                                                       
                                                                                                                       .provision_type>span {
                                                                                                                           font-size: 1.2rem;
                                                                                                                           display: block;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #adv_search .fdr_label,
                                                                                                                       #adv_search .fdr_text {
                                                                                                                           text-align: left;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #adv_search .fdr_text {
                                                                                                                           margin-left: 5px!important
                                                                                                                       }
                                                                                                                       
                                                                                                                       #adv_search .fdr_label {
                                                                                                                           margin-left: 0px!important;
                                                                                                                           font-weight: bolder!important;
                                                                                                                           font-size: 1rem!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .fdr_text.pubYear span {
                                                                                                                           margin-right: 20px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #adv_search input,
                                                                                                                       #adv_search textarea {
                                                                                                                           background-color: white;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .fdr_query.row {
                                                                                                                           margin-left: unset;
                                                                                                                           margin-right: unset;
                                                                                                                           margin-bottom: 15px
                                                                                                                       }
                                                                                                                       
                                                                                                                       #adv_search .col.s8.fdr_text {
                                                                                                                           margin-right: 5px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .pagination_title.on {
                                                                                                                           background: black;
                                                                                                                           color: white;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .pagination_title.off {
                                                                                                                           background: unset;
                                                                                                                           color: unset;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .treaties_title {
                                                                                                                           font-size: 1.4rem;
                                                                                                                           /* font-weight: bolder; */
                                                                                                                       }
                                                                                                                       
                                                                                                                       .treaties a {
                                                                                                                           font-weight: unset!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
                                                                                                                           height: 15px;
                                                                                                                           width: 15px;
                                                                                                                           background-color: transparent;
                                                                                                                           border: 1px solid #5a5a5a;
                                                                                                                           top: 0px;
                                                                                                                           z-index: 0;
                                                                                                                           transform: translateY(6%);
                                                                                                                       }
                                                                                                                       
                                                                                                                       .measureCitedInFull span {
                                                                                                                           display: block;
                                                                                                                           margin-top: 5px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #results_tab .no_results {
                                                                                                                           font-size: 1.5rem;
                                                                                                                           display: none;
                                                                                                                           padding: 10px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .facet-nav .collapsible-body .badge {
                                                                                                                           padding-left: 2px!important;
                                                                                                                           padding-right: 2px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       th span.counter {
                                                                                                                           float: right;
                                                                                                                       }
                                                                                                                       
                                                                                                                       #allresults table td,
                                                                                                                       #allresults table th {
                                                                                                                           padding: 6px!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .mapboxgl-map {
                                                                                                                           font-family: unset;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sidenav_react_buttons input[type=text] {
                                                                                                                           height: 2.5rem;
                                                                                                                           border-bottom: 2px solid #0f6cac;
                                                                                                                           margin-left: 4px;
                                                                                                                           font-size: unset;
                                                                                                                           width: 88%;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .sidenav_react_buttons>.collapsible li.active {
                                                                                                                           margin-bottom: 5px;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .mapboxgl-map {
                                                                                                                           font: unset!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .mapboxgl-ctrl-compass {
                                                                                                                           display: none!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .free_text_row {
                                                                                                                           width: 100%;
                                                                                                                           /* margin-left: 20px!important; */
                                                                                                                       }
                                                                                                                       
                                                                                                                       .free_text_row .fdr_label {
                                                                                                                           border: 1px solid red;
                                                                                                                           /* max-width: 70px; */
                                                                                                                       }
                                                                                                                       
                                                                                                                       .my_faoSelect__multi-value__label {
                                                                                                                           background: #2a8acc!important;
                                                                                                                           color: white!important;
                                                                                                                           font-size: 0.8rem!important;
                                                                                                                           padding-left: 1px!important;
                                                                                                                           text-transform: uppercase;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .fdr_label.col.s3 {
                                                                                                                           padding: unset!important;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .query_free {
                                                                                                                           width: 80%;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .material-icons.info {
                                                                                                                           color: #0f6cac;
                                                                                                                           font-size: 1.2rem;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .material-icons.info:hover {
                                                                                                                           cursor: pointer;
                                                                                                                       }
                                                                                                                       
                                                                                                                       .info.tooltipped {
                                                                                                                           /* background: pink!important; */
                                                                                                                       }
                                                                                                                       
                                                                                                                       .psm_title {
                                                                                                                           font-size: 1.4rem;
                                                                                                                           text-align: center;
                                                                                                                       }