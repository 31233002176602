.accordionProfile:after {
    content: "\2228";
    /* color: #0D6CAC; */
    color: black;
    font-weight: bold;
    float: right;
    margin-left: 5px;
    font-size: 15;
}


/* .active, .accordion:hover{
    content: "\2228";
      color: white;
  } */

.accordionProfile.active:after {
    content: "\2227";
    /* color: white; */
    color: black;
}

.accordionProfile {
    background-color: white;
    /* color: #0D6CAC; */
    color: black;
    cursor: pointer;
    padding: 30px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    font-weight: bold;
    margin-top: 30px;
}

.panelProfile {
    padding: 0 7px;
    display: none;
    background-color: white;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
}

.rfmo_name h5 {
    font-size: 1.64rem;
    line-height: 110%;
    text-transform: uppercase;
    color: #0f6cac;
    font-weight: 520;
}

.rfmo_collapsibles .collapsible-header {
    padding: 5px;
}

.rfmo_collapsibles .collapsible-header .col.title {
    font-size: 13px;
    font-weight: 500;
    color: #0f6cac;
    text-transform: uppercase;
    padding: 5px;
}

.basic_info,
.agreement_info {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-size: italic;
}

.basic_info a,
.agreement_info a {
    display: inline!important;
    font-size: 13px;
}

.basic_info div.title,
.agreement_info div.title {
    margin-left: 3px;
    font-size: 13px;
    /* font-weight: bold; */
}

#order_form label {
    color: black;
}

#order_form {
    margin-left: 10px;
}

#order_form span {
    padding-left: 2rem;
    margin-right: 10px;
}