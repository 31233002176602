#adv_search .fdr_text #advyearto,
#adv_search .fdr_text #advyearfrom {
    width: 75px !important;
    margin-right: 5px !important;
    font-family: inherit;
}

div.searchbox {
    margin-top: 40px;
    margin-bottom: 0px;
    min-height: 75px;
}

div.searchbox div.sbHolder {
    float: left;
    margin-right: 15px;
    border: 1px solid #cfcfcf;
}

div.searchbox form:after {
    content: '';
    display: block;
    clear: both;
}

input.ui-autocomplete-input {
    float: left;
    margin-right: 15px;
    height: 18px;
    border: 1px solid #cfcfcf;
    font-size: 13px;
    /*padding: 4px; width: 425px; */
    font-family: inherit;
}

.searchbox .queries {
    margin: 0 auto;
    width: auto;
}

.searchbox .queries input#query_home {
    float: left;
    margin-right: 15px;
    height: 18px;
    border: 1px solid #cfcfcf;
    font-size: 13px;
    padding: 4px;
    font-family: inherit;
    width: 94%;
}

input#btnG {
    background: rgba(0, 0, 0, 0) linear-gradient(#0e6da5, #0d5399) repeat scroll 0 0;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0 2px 3px #d1cece;
    color: #fff !important;
    font-size: 1em !important;
    height: 29px !important;
    line-height: 29px !important;
    margin-right: 12px;
    padding: 0 10px !important;
    position: relative;
    font-family: inherit;
    text-transform: uppercase;
}

.button-search {
    background: transparent linear-gradient(rgb(14, 109, 165), rgb(13, 83, 153)) repeat scroll 0px 0px;
    width: 28px;
    border-radius: 1px;
    color: rgb(255, 255, 255);
    text-align: center;
    float: left;
    height: 28px;
    font-size: 17px;
    line-height: 26px;
    margin-left: -17px;
    cursor: pointer;
}

#top-banner #adv_search .fdr_check {
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: black
}

#top-banner #adv_search .fdr_check.multicheck {
    float: left;
    width: 100% !important;
    margin-top: 10px !important;
    text-align: center !important;
}

#top-banner #adv_search .fdr_check.multicheck.internal {
    float: left;
    margin-left: 20px;
}