.cs-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.cs-loader-inner {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: calc(100% - 200px);
    color: #FFF;
    padding: 0 100px;
    text-align: center;
    opacity: 0.7;
}

.cs-loader-inner label {
    font-size: 30px;
    opacity: 0;
    display: inline-block;
}

@keyframes lol {
    0% {
        opacity: 0;
        transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        transform: translateX(0px);
    }
    66% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(300px);
    }
}

@-webkit-keyframes lol {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    66% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(300px);
    }
}

.cs-loader-inner label:nth-child(8) {
    -webkit-animation: lol 3s infinite ease-in-out;
    animation: lol 3s infinite ease-in-out;
    color: #0d5399;
}

.cs-loader-inner label:nth-child(7) {
    -webkit-animation: lol 3s infinite ease-in-out;
    animation: lol 3s 100ms infinite ease-in-out;
    color: #0e6da5;
}

.cs-loader-inner label:nth-child(6) {
    -webkit-animation: lol 3s infinite ease-in-out;
    animation: lol 3s 200ms infinite ease-in-out;
    color: #82b5d4;
}

.cs-loader-inner label:nth-child(5) {
    -webkit-animation: lol 3s 100ms infinite ease-in-out;
    animation: lol 3s 300ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(4) {
    -webkit-animation: lol 3s 200ms infinite ease-in-out;
    animation: lol 3s 400ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(3) {
    -webkit-animation: lol 3s 300ms infinite ease-in-out;
    animation: lol 3s 500ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(2) {
    -webkit-animation: lol 3s 400ms infinite ease-in-out;
    animation: lol 3s 600ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(1) {
    -webkit-animation: lol 3s 500ms infinite ease-in-out;
    animation: lol 3s 700ms infinite ease-in-out;
}

div.centered {
    width: 1000px;
}

div.columns {
    margin: 0 auto;
}

div.grey {
    background-color: #cccccc;
    width: 700px;
    float: left;
}

div.red {
    background-color: #e14e32;
    width: 300px;
    float: left;
}

div.clear {
    clear: both;
}

div.columns2 {
    width: 400px;
    margin: 0 auto;
}

.parent-item {
    color: #0A416B;
}

.left-item,
.right {
    float: left;
    width: 30%;
    padding: 5px;
    font: 900;
    color: black;
}

.right-item {
    float: left;
    width: 65%;
    padding: 5px;
}


/* Red border */

hr {
    margin-top: 5%;
    border-top: 1px solid lightgrey;
}

.measure-cited-full {
    text-align: left;
}

.react-expand-collapse__content {
    position: relative;
    overflow: hidden;
}

.react-expand-collapse__body {
    display: inline;
}


/* expand-collapse button */

.react-expand-collapse__button {
    float: right;
    color: #22a7f0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.react-expand-collapse__button:before {
    float: right;
    content: '';
    top: 0;
    left: -25px;
    width: 20px;
    height: 100%;
}


/* expanded state */

.react-expand-collapse--expanded .react-expand-collapse__button {
    padding-left: 5px;
    position: relative;
    bottom: auto;
    right: auto;
}

.react-expand-collapse--expanded .react-expand-collapse__button:before {
    content: none;
}