input {
    border: 1px solid #999;
    padding: 0.5rem;
    width: -webkit-fill-available;
    height: 25px;
    font-size: small;
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    /* border: 1px solid #999; */
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: auto;
    max-block-size: fit-content;
}

.my_faoSelect__menu-list .my_faoSelect__option,
.suggestions li {
    position: relative;
    z-index: 33333;
    padding: 0.5rem;
    /* font-size: x-small; */
    color: white;
    background-color: #0D6CAC;
}

.my_faoSelect__menu-list .my_faoSelect__option:hover,
.suggestion-active,
.suggestions li:hover {
    background-color: #1e9aec;
    color: white;
    cursor: pointer;
    /* font-weight: 700; */
    /* font-size: x-small; */
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
}

.my_faoSelect__menu {
    z-index: 22!important;
}

.my_faoSelect__placeholder {
    font-size: 14px!important;
}