.App {
    text-align: left;
    padding-right: 10px;
    padding-left: 10px;
    /* margin: 0 20px 30px 20px; */
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App .header .home {
    font-size: 48px;
    position: relative;
    /* top: 40%;
    transform: translateY(-40%); */
    left: 20%;
}

.App .header .main_title {
    font-size: 2rem;
    font-weight: bolder;
    /* word-spacing: 8rem; */
    margin-bottom: 8px;
}

.App .header .col.s9 {
    margin-top: 10px;
}

.App .header.row>.col {
    position: relative;
    top: 16%;
    /* margin-left: 25%; */
}

.App .header.row .left a {
    color: white;
}

.App .header.row .s9 span {
    margin: 0;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

header {
    padding: 0;
    margin: 0 20px 30px 0px;
    background-color: #0D6CAC;
    height: 90px;
    position: relative;
}

.footer {
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    color: #0D6CAC;
    /* text-align: center; */
}

.contact {
    color: #323232;
    padding: 10px;
    height: 50px;
    margin-top: 10px;
    /* padding-right: 113px; */
}

h1#logo-ltr {
    font-size: 1px;
    margin-top: 0;
    padding-top: 10px;
    float: left;
}


/* LEFT MENU ################################## */

.vertical-menu {
    /* width: 20%; */
    height: fit-content;
    background-color: white;
    /* margin: 0 20px 30px 20px; */
}

.page-content a {
    /* background-color: #eee; */
    color: #816d6d;
    display: block;
    padding: 3px;
    text-decoration: none;
    text-align: left;
}

div .search-app-container a {
    color: #ac0d42;
    padding: 0px;
    text-decoration: none;
}


/* ############################################## */


/* ACCORDION IN THE LEFT MENU */

.accordion {
    background-color: white;
    color: #605151;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
}

.accordion.fake_accordion a {
    color: #605151!important;
}

.accordion:hover {
    color: white;
    background-color: #0d7fcd;
}

.panel {
    padding: 0 0px;
    /* display: none; */
    overflow: visible;
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    /* background: #d6d4d4; */
    /* blacker */
    color: #43434c;
}


/* ################################################ */

.react-select__menu {
    overflow: visible;
    z-index: 100;
}

.links-wrapper a {
    text-decoration: none
}

#map {
    height: 60vh;
    margin-top: 0px;
    width: 100%;
    border: 1px solid #ddd6d6;
}

.page-content {
    display: flex;
}

.mySelect__menu-list {
    max-height: 50px !important;
}

.mapboxgl-ctrl-bottom-right {
    pointer-events: unset!important;
}

#legend {
    cursor: pointer;
    z-index: 111111;
    font-size: 0.9rem;
}

#legend .row .s9 {
    margin-top: 5px;
}

#legend .row {
    opacity: 0.4;
    margin-bottom: 5px;
}

#legend .row.active {
    opacity: 1;
}

.map_legend_control_container div {}

#allresults {
    /* max-height: 900px; */
    overflow: auto;
    margin-top: 0px!important;
}

.portlexDescription a {
    color: #10ebd7!important;
    font-size: unset!important;
    display: inline!important;
    padding: unset;
}

.portlexDisclaimer a {
    color: #10ebd7!important;
    font-size: unset!important;
    display: inline!important;
    padding: unset;
}

.page-content.row {
    margin-bottom: unset;
}

.fdr_query .fdr_check {
    margin-top: 11px;
}

input {
    pointer-events: unset;
}

.mapboxgl-popup-content .country_popup div {
    display: none;
}

.mapboxgl-popup-content .country_popup div.adm_name {
    display: block;
}

.accordion.fake_accordion {
    border: 1px solid lightgrey;
}

.accordion.fake_accordion a {
    padding: unset!important;
    font-size: 1rem;
}

#verticalMenu .accordion {
    border-bottom: 0px!important;
}

#verticalMenu .panel {
    /* border-top: 1px solid lightgrey; */
    background-color: rgb(0 0 0 / 4%);
}

.App .treaties div {
    width: fit-content;
}

.comparing_div {
    background: rgb(255 255 255);
}

html {}

body {
    color: #211d1d!important;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

body::-webkit-scrollbar {
    width: 0;
    height: 0;
    /* Safari and Chrome */
}

#root,
html,
body,
.divRouting,
.sidenav_react {
    background: white!important;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    word-wrap: break-word;
    -webkit-text-size-adjust: 100%;
    font-weight: 100;
}
#root 
{
    height: 100vh;
    overflow: auto;
}
.divRouting {
    overflow-x: hidden;
    margin-top: 7px;
}

.divRouting>div.parent-item {
    margin-top: 5px;
}

.sidenav_react {
    padding: 0px!important;
}

.mapboxgl-ctrl-bottom-left {
    display: none!important;
}

.sidenav_react_buttons .collapsible-body {
    max-height: unset!important;
    padding: 0.1rem;
    overflow-x: hidden;
}

.sidenav_react_buttons .collapsible-header a {
    padding: unset;
    font-size: unset;
    color: unset!important;
}

.sidenav_react_buttons .collapsible-header {
    font-size: 1rem;
}

.sidenav_react_buttons .collapsible-header.active {
    background: #0f6cac;
    color: white!important;
}

.sidenav_react_buttons .collapsible-header:hover {
    background-color: #0d7fcd;
    color: white;
}

.sidenav_react_buttons .collapsible-header a:hover {
    font-style: unset;
}

.sidenav_react_buttons ::-webkit-scrollbar {
    background-color: #dce1e4;
}

.sidenav_react_buttons ::-webkit-scrollbar-thumb {
    background-color: #0f6cac;
    height: 10%;
    border-radius: 10px;
}

::-webkit-scrollbar {
    background-color: #dce1e4!important;
}

::-webkit-scrollbar-thumb {
    background-color: #0f6cac!important;
    height: 10%;
    border-radius: 10px!important;
}

.top_map_info span {
    font-size: 1rem;
    text-align: center;
    /* max-width: 170px; */
    color: #0d59ad;
    font-weight: 300;
    min-width: 300px;
    background-color: #dcecec!important;
    padding: 4px!important;
    border: 2px solid #0d59ad
}

.top_map_info {
    opacity: 0;
    -webkit-transition: opacity .5s;
    -moz-transition: opacity .5s;
    background-image: none!important;
    border-radius: 0px!important;
    /* max-height: 56px; */
    /* border-color: #d6e9c6; */
    z-index: 1111111111111111111111111111;
    position: absolute;
    top: 20px;
    width: 100%;
    padding: 8px;
}

.mapboxgl-marker.present path,
.with_national_plan path {
    fill: #20ac20!important;
}

.mapboxgl-marker.not_present path,
.no_national_plan path {
    fill: #f22626!important;
}

[type="checkbox"].filled-in:checked+span:not(.lever):after {
    top: 0;
    width: 15px;
    height: 15px;
    /* border: 2px solid #26a69a; */
    background-color: #2771cd;
    z-index: 0;
}

[type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
    height: 15px;
    line-height: 15px;
    width: 15px;
}

[type="checkbox"]+span:not(.lever) {
    position: relative;
    /* margin-left: 15px; */
    height: 15px;
    line-height: 15px;
}

[type="checkbox"].filled-in:checked+span:not(.lever):before {
    top: -2px;
    left: 1px;
    width: 6px;
    height: 10px;
}

.facet-nav .collapsible-body .row.valign-wrapper {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    /* margin-top: 4px; */
    border-bottom: 1px solid #d4d1d1;
    padding: 4px;
    font-size: 0.9rem;
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
    background-color: #318dca!important;
}



.footer_extra_container {
    border-top: 1px solid #d6d4d4;
    margin-top: 35px;
}

.footer_span {
    padding-right: 35px !important;

}