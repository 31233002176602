.accordionProfile:after {
    content: "\2228";
    /* color: #0D6CAC; */
    color: black;
    font-weight: bold;
    float: right;
    margin-left: 5px;
    font-size: 15;
}


/* .active, .accordion:hover{
    content: "\2228";
      color: white;
  } */

.accordionProfile.active:after {
    content: "\2227";
    /* color: white; */
    color: black;
}

.accordionProfile {
    background-color: white;
    /* color: #0D6CAC; */
    color: black;
    cursor: pointer;
    padding: 30px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    font-weight: bold;
    margin-top: 30px;
}

.panelProfile {
    padding: 0 7px;
    display: none;
    background-color: white;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
}

.country_collapsibles_wrapper>ul.col.s4 {
    width: 31%!important;
    margin: 3px;
}

.country_collapsibles_wrapper .collapsible-header {
    padding: 5px;
}

.country_collapsibles_wrapper .collapsible-header .col.s1 {
    padding: 0px;
    margin-top: 8px;
}

.collapsible-header .material-icons {
    /* margin-left: 80%; */
}

.collapsible-header span.col.s8 {
    /* width: 90%; */
    font-size: 1rem;
    text-align: left;
    width: 30%!important;
    margin: 3px;
}

.collapsible {
    width: 100%;
    padding: 0px!important;
    margin-right: 10px;
}


/* 
.collapsible.national_plans {
    margin-left: 10px!important;
} */

.badge {
    background-color: #0d6cac!important;
    font-weight: 300;
    font-size: 0.9rem;
    color: #fff;
    /* border-radius: 2px; */
}

.collapsible-body {
    padding: 0.5rem;
    max-height: 250px;
    overflow-y: auto;
}

.provision_type>div {
    width: 40%;
    text-align: left;
}

.stats_collection .badge {
    font-size: 0.7rem;
}

.stats_collection .collection .collection-item span.badge {
    width: auto;
    line-height: 1.2rem;
    padding: 5px 5px;
    font-size: 0.8rem;
    line-height: 9px;
    height: 18px;
}

.stats_collection .collection.with-header .collection-header {
    padding: 7px 5px;
    font-size: 1rem;
}

.stats_collection .collection.with-header .collection-item {
    padding: 5px;
    padding-left: 10px;
    color: #4e606e;
}

.stats_collection ul.collection {
    max-width: 350px;
    max-height: 200px;
    overflow-y: auto;
}

.query_results {
    max-height: 570px;
    overflow-y: auto;
    margin-left: 0px;
    margin-top: 20px;
    background: #e3e1e1;
}

.query_results table td,
.query_results table th {
    /* padding: 5px 2px; */
    padding: 10px;
}

.query_results table {
    background-color: white;
}

.query_results span.sel {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
}

.parent-item {
    color: #7f7f7f!important
}

.query_results .parent-item-container {
    /* border: unset!important; */
    margin: 2px;
    margin-left: 7px;
    font-size: 1rem;
}

.query_results table tbody .td_title {
    font-weight: 400;
    width: 15%;
}

.header-parent-item {
    margin-bottom: 5px;
    color: #0f6cac!important;
}

.no_results {
    display: none;
    color: brown;
}

.country_collapsibles_wrapper .col.s9 {
    font-size: 1rem;
}

.treaties>div {
    margin-top: 7px;
}

.treaties {
    margin-top: 15px;
    margin-bottom: 11px;
}

.download_order .col.s9 div {
    display: inline-block;
}

.row.country_collapsibles_wrapper {
    margin-left: 0px!important;
}

#order_form label {
    color: black;
}

#order_form {
    margin-left: 10px;
}

#order_form span {
    padding-left: 2rem;
    margin-right: 10px;
}